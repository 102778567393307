import images from './images';

const services = [
  {
    imgUrl: images.amblem,
    title: 'Products',
    subtitle: 'Discover our selection of festive items designed for the holiday season! Our Christmas collection features unique and creative gifts that bring joy and warmth to your celebrations.',
  },
  {
    imgUrl: images.amblem,
    title: 'Event Planning',
    subtitle: 'Stay tuned for our upcoming event planning services! We’re excited to help you create unforgettable experiences.',
  },
];

export const featuredProducts = [
  {
    id: 1,
    name: 'Featured Product 221',
    price: '$50.00',
    description: 'This is a description for featured product 1.',
    category: "Bracelet",
    imageUrl: images.welcomePage,
  },
  {
    id: 2,
    name: 'Featured Product 2',
    price: '$60.00',
    description: 'This is a description for featured product 2.',
    category: "Necklace",
    imageUrl: images.cupWelcomePage,
  },
  {
    id: 1,
    name: 'Featured Product 1',
    price: '$50.00',
    description: 'This is a description for featured product 1.',
    category: "Bracelet",
    imageUrl: images.welcomePage,
  },
  {
    id: 2,
    name: 'Featured Product 2',
    price: '$60.00',
    description: 'This is a description for featured product 2.',
    category: "Necklace",
    imageUrl: images.cupWelcomePage,
  },
  {
    id: 1,
    name: 'Featured Product 1',
    price: '$50.00',
    description: 'This is a description for featured product 1.',
    category: "Bracelet",
    imageUrl: images.welcomePage,
  },
  {
    id: 2,
    name: 'Featured Product 2',
    price: '$60.00',
    description: 'This is a description for featured product 2.',
    category: "Necklace",
    imageUrl: images.cupWelcomePage,
  },
];

export const normalProducts = [
  {
    id: 1,
    name: 'Heavenly Guardian (1 piece)',
    tagline: 'Enchanting Glass Bauble Tied up with Love and Snow!',
    description: 'An enchanting glass ornament featuring a delicate angel figurine, perfect for adding a touch of heavenly charm to your holiday decor!',
    price: '$7.00',
    category: '',
    imageUrl: [images.product1],
    showOptions: false
  },
  {
    id: 2,
    name: 'Heavenly Guardian Trio',
    tagline: 'Enchanting Glass Baubles Tied up with Love and Snow!',
    description: 'Three enchanting glass ornaments featuring delicate angel figurines, perfect for adding a touch of heavenly charm to your holiday decor!',
    price: '$18.00',
    category: '',
    imageUrl: [images.product2],
    showOptions: false
  },
  {
    id: 3,
    name: 'Frosty Charm (1 piece)',
    tagline: 'Charming Snowman Magic to Brighten Your Holidays!',
    description: 'A lovely plexi ornament featuring a cheerful snowman figurine, perfect for adding festive joy to your holiday decor!',
    price: '$5.00',
    category: '',
    imageUrl: [images.product31],
    showOptions: false
  },
  {
    id: 4,
    name: 'Frosty Charm Trio',
    tagline: 'Charming Snowman Magic to Brighten Your Holidays!',
    description: 'A set of three plexi ornaments, each featuring a cheerful snowman figurine, perfect for spreading joy throughout your holiday decor!',
    price: '$12.00',
    category: '',
    imageUrl: [images.product3],
    showOptions: false
  },
  {
    id: 5,
    name: 'The Feel Good Kit',
    tagline: 'Why wait for Santa? Treat Yourself or a Loved One to a Day of Relaxation!',
    description: 'Cute motivation card, charming gray headband with a bow, silk scrunchie, soft gray face towel, all-natural regular-sized scented soap bar for the face, body, and hands (SALMA), vanilla-scented bath bomb (Arsai), soothing vanilla bath salt jar (Arsai), rejuvenating Gua Sha for the perfect relaxation day!',
    price: '$19.00',
    category: '',
    imageUrl: [images.product42, images.product4],
    showOptions: false
  },
  {
    id: 6,
    name: 'You Time Essentials',
    tagline: 'Embrace the Cozy with our Essentials for Relaxation!',
    description: 'Cute motivation card, soft off-white face towel, mini bamboo hairbrush, silk scrunchie, all-natural scented soap bar (SALMA) for face, body, and hands, pocket-sized soap bar (SALMA), matching hand and body lotion (SALMA), and soothing vanilla bath salt jar for a perfect relaxation day!',
    price: '$20.00',
    category: '',
    imageUrl: [images.product51, images.product5],
    showOptions: false
  },
  {
    id: 7,
    name: 'Christmas Wonder Socks',
    tagline: 'The Perfect Gift to Keep the Feet Warm and Wrapped in Comfort!',
    description: 'A festive box featuring 3 Christmas-themed socks: 1 pair of soft wool socks for cozy comfort, 2 pairs of fun cotton socks to complete your festive looks!',
    price: '$10.00',
    category: '',
    imageUrl: [images.product6, images.product61],
    showOptions: false
  },
  {
    id: 8,
    name: 'Tea and Tranquilitea',
    tagline: 'A Cozy Blend of Tea and Treats!',
    description: 'Glass mug paired with its coaster, deliciously baked anise & dates cookies (Mandy’s), festive candle for a warm glow, selection of soothing and flavorful tea sachets.',
    price: '$16.00',
    category: '',
    imageUrl: [images.product71, images.product7],
    showOptions: false
  },
  {
    id: 9,
    name: 'The Daily Brew',
    tagline: 'Brew Happiness, One Sip at a Time!',
    description: '2 glass espresso cups paired with their coasters, delicious Biscoff treats for a sweet touch, glass jar filled with chocolate-coated coffee beans for the perfect coffee experience!',
    price: '$14.00',
    category: '',
    imageUrl: [images.product81, images.product8],
    showOptions: false
  },
  {
    id: 10,
    name: 'Santa’s Delights',
    tagline: 'A Secret Santa Gift Crafted to Warm the Heart and Spread Holiday Cheer!',
    description: 'Festive Christmas mug for cozy sips, star-shaped glass jar filled with mini fluffy marshmallows, star-shaped glass jar filled with Cadbury hot cocoa powder for a rich, delicious drink, festive socks to keep your feet warm on those chilly Christmas nights!',
    price: '$10.00',
    category: '',
    imageUrl: [images.product101, images.product10],
    showOptions: false
  },
  {
    id: 11,
    name: 'Secret Goodies',
    tagline: 'A Surprise Gift to Warm Hearts and Spread Holiday Joy!',
    description: 'Festive Christmas mug, star-shaped glass jar with mini marshmallows, mini Lindt chocolate bear, festive socks to keep your feet warm and stylish!',
    price: '$10',
    category: '',
    imageUrl: [images.product111, images.product11],
    showOptions: false
  },
  {
    id: 12,
    name: 'The Christmas Experience',
    tagline: 'Craft Your Perfect Christmas Experience with our Box of Warmth and Cheer!',
    description: 'Soft white fuzzy TV blanket (127x152cm), Christmas-themed socks, playful deer headband, festive mug, coffee sachets, vanilla/coconut-scented candle, mini wooden house for festive decor!',
    price: '$32',
    category: '',
    imageUrl: [images.product121, images.product12],
    showOptions: false
  },
  {
    id: 13,
    name: 'Whispers of Winter',
    tagline: 'Want to Feel Warm and Cozy? This Bundle is Perfect for You!',
    description: 'Cozy beige blanket (160x220cm), fluffy socks, cute mug, coffee sachets, vanilla/coconut-scented Christmas-themed candle , mini wooden ornament for festive decor!',
    price: '$26',
    category: '',
    imageUrl: [images.product131, images.product13],
    showOptions: false
  },
  {
    id: 14,
    name: 'Snuggle Season',
    tagline: 'Snuggle Up and Enjoy the Comforts of the Season!',
    description: 'Cozy gray blanket (160x220cm) , festive wool socks, cute mug, coffee sachets, vanilla/coconut-scented Christmas-themed candle, decorative wooden piece for festive decor!',
    price: '$25',
    category: '',
    imageUrl: [images.product141, images.product14],
    showOptions: false
  },
  {
    id: 15,
    name: 'Gems of Lebanon',
    tagline: 'Celebrating the Rich Flavors of Lebanese Mouneh!',
    description: 'Arak Baladi (250ml), Zeitoun Mathoun, Zeitoun Makdous, Goat Labneh bel Zeit, Kaak b Zaatar, Mrabba el Fraise.',
    price: '$30',
    category: 'Mouneh - Large',
    imageUrl: [images.product15, images.product151],
    showOptions: false
  },
  {
    id: 16,
    name: 'Tiny Mouneh Treasures',
    tagline: 'A Generous Selection of Savory and Sweet Mouneh!',
    description: 'Arak Baladi (250ml), Goat Labneh bel Zeit, Goat Labneh bel Na3na3 el Yebis, Zaatar Baladi, Zeitoun Makdous, Zeitoun Mathoun, Mrabba el Tin.',
    price: '$24',
    category: 'Mouneh - Small',
    imageUrl: [images.product161, images.product16],
    showOptions: false
  },
  {
    id: 17,
    name: 'Sweet Mouneh Moments',
    tagline: 'Featuring a Mix of Sweet Treats and Traditional Flavors!',
    description: 'Sharab el Afandi, Mrabba el Fraise, Mrabba el Tin, Halawa bi Festok, Kaak bel Zbib w Joz.',
    price: '$20',
    category: 'Mouneh - Small',
    imageUrl: [images.product171, images.product17],
    showOptions: false
  },
  {
    id: 18,
    name: 'Winter Soirees',
    tagline: 'Celebrate the Season with Crunchy Comforts!',
    description: 'Elegant square straw tray, mini Ksara red wine bottle (250ml), 2 wine glasses for sharing moments, round cheese board with knives, glass jar with crunchy nuts.',
    price: '$25',
    category: 'Wine Set - Small',
    imageUrl: [images.product18],
    showOptions: false
  },
  {
    id: 19,
    name: 'Savor the Season',
    tagline: 'Sip, Savor and Celebrate!',
    description: 'Elegant square straw tray, Kefraya red wine bottle (375ml), 2 wine glasses, round cheese board with knives, 2 small glass jars with crunchy nuts.',
    price: '$32',
    category: 'Wine Set - Medium',
    imageUrl: [images.product19, images.product191],
    showOptions: true,
    wineOptions: [
      { id: 1, label: 'Kefraya red wine bottle (375ml)', price: 32 },
      { id: 2, label: 'Ksara red wine bottle (375ml)', price: 32 },
      { id: 3, label: 'Ksara white wine bottle (375ml)', price: 32 },
      { id: 4, label: 'Kefraya white wine bottle (375ml)', price: 32 }
    ],
    outOfStock: true,  
  },
  {
    id: 20,
    name: 'The Grand Experience',
    tagline: 'Gather, Savor, and Create Everlasting Memories!',
    description: 'Elegant square straw tray, Ksara red wine bottle (750ml), 2 wine glasses, asymmetrical cheese board with knives, plexi baubles filled with a variety of cracker flavors and crunchy nuts for the perfect savory snack!',
    price: '$50',
    category: 'Wine Set - Large',
    imageUrl: [images.product20, images.product201, images.grandexperience],
    showOptions: true,
    wineOptions: 
    [
      { id: 1, label: 'Ksara red wine bottle-Cabernet (750ml) - 50$', price: '$50' },
      { id: 2, label: 'Ksara red wine bottle-Reserve (750ml) - 48$', price: '$48' },
      { id: 3, label: 'Ksara white wine bottle (750ml) - 45$', price: '$45' },
      { id: 4, label: 'Chateau Ka red wine bottle (750ml) - 45$', price: '$45' },
      { id: 5, label: 'Kefraya red wine bottle (750ml) - 46$', price: '$46' },
      { id: 6, label: 'Ksara Le Souverain Premium red wine bottle-less than 10 000 bottles per year (750ml) - 70$', price: '$70' }
    ],
  },
  {
    id: 21,
    name: 'Raise a Toast',
    tagline: 'Tis the Season to Drink Whiskey!',
    description: '3 mini whiskey bottles, elegant whiskey glass for savoring every sip, bauble filled with nuts, adding a tasty crunch!',
    price: '$18',
    category: 'Alcohol',
    imageUrl: [images.product21],
    showOptions: false
  },
  {
    id: 22,
    name: 'Holiday Cheers',
    tagline: 'Cheers to Bold Moments: Take Your Shot!',
    description: '2 mini vodka bottles, glass shot filled with crunchy nuts for a delightful pairing!',
    price: '$12',
    category: 'Alcohol',
    imageUrl: [images.product22],
    showOptions: false
  },
  {
    id: 23,
    name: 'Merry Movie Marathon',
    tagline: 'Watch, Solve, Win!',
    description: '🎄 Challenge yourself to complete the Merry Movie Marathon! 📅 Watch 10 Netflix Christmas movies from our special calendar, 🧩 solve the enigmas that come with each movie card, and 📸 share your filled calendar with us to enter the draw. 🎁 Get a chance to win a voucher while enjoying a cozy marshmallow bag to enhance the festive vibes. 🎅 This is perfect for Secret Santa gifts!',
    price: '$6.00',
    imageUrl: [images.id231, images.id232],
    showOptions: false, 
  },
  {
    id: 24,
    name: 'Creative Sips',
    tagline: 'Cheers to Festive Creativity!',
    description: '🎨 Unleash your creativity with this DIY activity for couples, family, and friends. 🍷 Craft memories together by painting on 2 large wine glasses with a Christmas-themed coloring painting kit. 🖌️ The kit includes 2 paint brushes, 🍾 half a bottle of red wine, and 📜 an instruction and inspiration card with drawing ideas. 🎁 It’s perfect for holiday gatherings and thoughtful gifts!',
    price: '$20.00',
    imageUrl: [images.id24],
    showOptions: false,
  },
  {
    id: 25,
    name: 'Festive Flames',
    tagline: 'Light Up Your Holidays with Elegance!',
    description: '🎄 Add an elegant festive touch with this set of 3 hand-decorated candles placed on a classy concrete tray. ✨ These candles bring warmth and charm to your festive celebrations!',
    price: '$26.00',
    imageUrl: [images.id251, images.id252, images.id253],
    showOptions: false,
  },
  {
    id: 26,
    name: 'Tree of Serenity',
    tagline: 'Embrace Tranquility with Every Glow!',
    description: '🌲 Create a calming ambiance with this decorated scented candle, combining the soothing scents of vanilla and coconut. 🕯️ It’s placed in a wooden tree-shaped tray, adding a natural and elegant touch. ✨ Perfect for creating a serene atmosphere in any room!',
    price: '$14.00',
    imageUrl: [images.id261, images.id262],
    showOptions: false,
  },
  {
    id: 27,
    name: 'Elf Snacks',
    tagline: 'Sweet Surprises from Santa’s Helpers!',
    description: '🎁 Delight in this holiday box filled with your favorite candies, including 🍫 mini Kinder Bueno bars, 🍬 Kinder Shoko-bons, 🍭 jars of Smarties and chocolate M&M’s, 🧸 mini Lindt bear and Santa chocolates, ☁️ marshmallows, 💋 Hershey’s Kisses, 🍭 a candy cane, and ☕ a Christmas mug.',
    price: '$15.00',
    imageUrl: [images.id271, images.id272],
    showOptions: false,
  },
  {
    id: 28,
    name: 'Choco Overload',
    tagline: 'A Hot Chocolate Lover’s Dream!',
    description: '🍫 Treat yourself with the Choco Overload holiday box, which includes ☕ a jumbo cup for your favorite hot beverages, 🎄 a Christmas tree decoration for festive cheer, and 🦌 Rudolph’s Cravings in two sizes – cones filled with Cadbury hot chocolate mix and marshmallows. 🎅 It’s the ideal festive gift for chocolate lovers!',
    price: '$12.00',
    imageUrl: [images.id281, images.id282],
    showOptions: false,
  },
  {
    id: 29,
    name: 'Frosted Forest Centerpiece',
    tagline: 'Bring the Enchantment of the Frosted Forest Home!',
    description: '🐿️ This elegant and adorable squirrel figurine is paired with 🌲 two vanilla and coconut scented tree candles, all laid on a snowy wooden piece to create a frosted forest ambiance. ✨ It adds warmth and charm to your holiday decor!',
    price: '$28.00',
    imageUrl: [images.id291, images.id292],
    showOptions: false,
  },
  {
    id: 30,
    name: 'Winter Wonderland Centerpiece',
    tagline: 'Add the Winter Wonderland experience to your Festive decor!',
    description: '🦌 This magical centerpiece features an elegant deer figurine, 🏠 a porcelain red house for a cozy holiday feel, and 🌲 two white trees placed on snowy wooden pieces to create a beautiful winter wonderland ambiance.',
    price: '$26.00',
    imageUrl: [images.id301],
    showOptions: false,
  },
  {
    id: 31,
    name: 'Rudolph’s Cravings',
    tagline: 'Time to try Rudolph’s Hot Cocoa Magic!',
    description: '🦌 Indulge in Rudolph’s Craving cones filled with Cadbury hot chocolate mix and marshmallows, available in 📏 two sizes (Large and Small) to suit every hot chocolate lover. 🎅 It’s the perfect Secret Santa gesture or festive gift!',
    price: '$5.00 and $3.00',
    imageUrl: [images.id31],
    showOptions: false,
  },
  {
    id: 32,
    name: 'Twelve Treasures',
    tagline: 'Experience the Elegance of Twelve Treasures!',
    description: '🥃 Enjoy a premium whiskey experience with this elegant square straw tray featuring 🍾 a Chivas 12 bottle, 🥂 two whiskey glasses, and 🎄 plexi baubles filled with crunchy nuts.',
    price: '$50.00',
    imageUrl: [images.id321, images.id322],
    showOptions: false,
  },
  {
    id: 33,
    name: 'Walker’s Voyage',
    tagline: 'Embark on a Journey of Elegance with Walker’s Voyage!',
    description: '🥃 This premium set includes an elegant square straw tray featuring 🍾 a Black Label whiskey bottle, 🥂 two whiskey glasses, and 🎄 plexi baubles filled with a variety of cracker flavors and crunchy nuts.',
    price: '$55.00',
    imageUrl: [images.id331, images.id332],
    showOptions: false,
  },
  {
    id: 34,
    name: 'Golden Celebration',
    tagline: 'Raise a Glass to Golden Moments!',
    description: '🥃 Celebrate in style with this elegant square straw tray featuring 🍾 a Gold Label whiskey bottle, 🥂 two whiskey glasses, and 🎄 plexi baubles filled with a variety of cracker flavors and crunchy nuts.',
    price: '$100.00',
    imageUrl: [images.id341, images.id342],
    showOptions: false,
  },
  {
    id: 35,
    name: 'Moët Moments',
    tagline: 'Moët: For Moments that Sparkle!',
    description: '🥂 Elevate your celebrations with this elegant square straw tray featuring 🍾 a bottle of Moët & Chandon, 🥂 two flute glasses for toasting in style, and 🍫 plexi baubles filled with premium chocolates for a luxurious indulgence.',
    price: '$90.00',
    imageUrl: [images.id351, images.id352
    ],
    showOptions: false,
  }
  
];


export default { services };